import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { baseUrl } from '../../Utils/serviceRequest';
import { Box, Button, Slide, Dialog, DialogContent, IconButton, Card, CardContent, Grid, MenuItem, Pagination, Select, Stack, Typography, Popover, CircularProgress, Backdrop } from '@mui/material';
import Excel from "exceljs";
import { saveAs } from "file-saver";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { Close } from "@mui/icons-material";
import { DataGrid, gridPageCountSelector, gridPageSelector, useGridApiContext, useGridSelector } from '@mui/x-data-grid';
import AllAppreciationPage from '../AllAppreciationPage/AllAppreciationPage';
import { setSelectedNavIndex } from '../../redux/reducers/appReducer';



const AnalyticsPage = () => {

    const dispatch = useDispatch();

    dispatch(setSelectedNavIndex(6));

    const [cardsData, setCardsData] = useState([
        {
            key: "TotalValueCard",
            label: "Total",
            count: 0,
            status: "",
        },
        {
            key: "TotalValueCardApproved",
            label: "Approved",
            count: 0,
            status: "approved",
        },
        {
            key: "TotalValueCardPending",
            label: "Pending",
            count: 0,
            status: "pending",
        },
        {
            key: "TotalValueCardRejected",
            label: "Rejected",
            count: 0,
            status: "rejected",
        },
    ]);
    const [isLoading, setIsloading] = useState(true);

    const [openDialog, setOpenDialog] = useState(false);

    const fetchCardsData = async () => {
        try {
            let res = await axios.get(
                `${baseUrl}/appreciation/getCountOfValueCards`
            );
            if (res.status === 200) {
                let tempCardsData = [...cardsData];
                tempCardsData.forEach((cardData) => {
                    cardData.count = res.data?.[cardData.key] ?? 0;
                })
                setCardsData(tempCardsData);
                setIsloading(false);
            }
        } catch (error) {
            console.error(error);
        }
    }
    useEffect(() => {
        fetchCardsData();
    }, [])

    const [selectedCardDataStatus, setSelectedCardDataStatus] = useState("");

    return (
        <>
            <Grid
                container
                gap={3}
                sx={{ padding: "1em 1em 0 1em" }}
                justifyContent="space-between">

                {
                    cardsData.map((cardData) => {
                        return <Grid item onClick={() => {
                            if (!isLoading) {
                                setSelectedCardDataStatus(cardData?.status)
                                setOpenDialog(true)
                            }
                        }} style={{ cursor: isLoading ? 'not-allowed' : 'pointer' }}>
                            <CustomCard cardData={cardData} />
                        </Grid>
                    })
                }
            </Grid>
            <ReportsTable isLoading={isLoading} cardsCountData={cardsData} />
            <StatusDialogDetail openDialog={openDialog} setOpenDialog={setOpenDialog} status={selectedCardDataStatus} />
        </>
    )

}

export default AnalyticsPage

const CustomCard = ({ cardData }) => {
    return (
        <Card sx={{
            borderRadius: "10px",
            width: "204px",
            height: "92px",
            boxShadow: " 0px 4px 8px rgba(187, 210, 227, 0.25)",
        }}>
            <CardContent>
                <Stack sx={{ textAlign: "center" }}>
                    <Typography variant="subtitle1" color="#777">
                        {cardData?.count}
                    </Typography>
                    <Typography
                        color="#3026B9"
                        fontSize="14px"
                    >
                        {cardData?.label}
                    </Typography>
                </Stack>
            </CardContent>
        </Card>
    )
}

const ReportsTable = ({ isLoading, cardsCountData }) => {
    // const [pageNumber, setPageNumber] = useState(1);
    // const [reportData, setReportData] = useState([]);
    const appReducer = useSelector(state => state.appReducer);

    // const [searchInput, setSearchInput] = useState("");
    // const [searchedData, setSearchedData] = useState([]);
    // const onChangeSearch = (e) => {
    //     setSearchInput(e.target.value);
    //     const tempData =
    //         reportData.filter(
    //             (item) => {
    //                 return Object.keys(item).some((key) =>
    //                     item[key]
    //                         ?.toString()
    //                         ?.toLowerCase()
    //                         ?.includes(e.target.value.toLowerCase())
    //                 );
    //             }
    //         );
    //     console.log(tempData);
    //     setSearchedData(tempData);
    // };


    // let getNextBatchOfData = () => {
    //     fetchData();
    // };

    const [status, setStatus] = useState("all");

    const saveExcel = async (exportAllData) => {
        const workbook = new Excel.Workbook();
        try {
            const fileName = `REPORT`;
            // creating one worksheet in workbook
            const worksheet = workbook.addWorksheet(`REPORT-SHEET`);

            worksheet.columns = [
                { header: "Sender", key: "sender" },
                { header: "Receiver", key: "receiver" },
                { header: "Category", key: "category" },
                { header: "Date", key: "date" },
                { header: "Reject Reason", key: exportAllData? "rejReason" : "rejectMessage" },
            ];

            // updated the font for first row.
            worksheet.getRow(1).font = { bold: true };

            // loop through all of the columns and set the alignment with width.
            worksheet.columns.forEach((column) => {
                column.width = column.header.length + 5;
                column.alignment = { horizontal: "center" };
            });

            // loop through data and add each one to worksheet
            if (exportAllData) {
                exportAllData?.forEach((singleData) => {
                    worksheet.addRow(singleData);
                });
            } else {
                pageState.data?.forEach((singleData) => {
                    worksheet.addRow(singleData);
                });
            }

            // loop through all of the rows and set the outline style.
            worksheet.eachRow({ includeEmpty: false }, (row) => {
                // store each cell to currentCell
                const currentCell = row._cells;

                // loop through currentCell to apply border only for the non-empty cell of excel
                currentCell.forEach((singleCell) => {
                    // store the cell address i.e. A1, A2, A3, B1, B2, B3, ...
                    const cellAddress = singleCell._address;

                    // apply border
                    worksheet.getCell(cellAddress).border = {
                        top: { style: "thin" },
                        left: { style: "thin" },
                        bottom: { style: "thin" },
                        right: { style: "thin" },
                    };
                });
            });

            // write the content using writeBuffer
            const buf = await workbook.xlsx.writeBuffer();

            // download the processed file
            saveAs(new Blob([buf]), `${fileName}.xlsx`);
            setIsExportingloading(false);
        } catch (error) {
            console.error("<<<ERRROR>>>", error);
            console.error("Something Went Wrong", error.message);
        } finally {
            // removing worksheet's instance to create new one
            // workbook.removeWorksheet(workSheetName);
        }
    };

    const cardsCountKey = {
        "all": "Total",
        "approved": "Approved",
        "rejected": "Rejected",
        "pending": "Pending"
    }

    const fetchData = async () => {
        try {
            setPageState((prev) => ({ ...prev, isLoading: true }))
            let url = status === "all" ?
                `${baseUrl}/appreciation/getAllAppreciation/50/${pageState.page}` :
                `${baseUrl}/appreciation/getAppreciationByStatus/50/${pageState.page}/${status}`
            let res = await axios.get(url);
            if (res.data.result === "success") {

                let tempReportDataHolder = [];
                res.data?.data?.forEach((element, index) => {
                    let templateData = appReducer.templateData?.find(temp => temp.templateId === element.templateId);
                    let obj = { ...element, template: templateData };
                    let tzoffset = (new Date()).getTimezoneOffset() * 60000;
                    let timestamp = Number(obj["date"]);
                    let date = new Date(timestamp - tzoffset)?.toISOString()?.substring(0, 10) ?? "-";

                    tempReportDataHolder.push({
                        id: obj["valueCardId"],
                        sender: obj["sender"]["emailId"],
                        receiver: obj["receiver"]["emailId"],
                        date: date,
                        category: obj["template"]["category"],
                        rejectMessage: obj["rejMessage"] ?? ""
                    })
                })
                let label = cardsCountKey[status]
                let totalCount = 0;
                cardsCountData?.forEach((card) => {
                    if (card.label === label) {
                        totalCount = card.count;
                    }
                })
                setPageState((prev) => ({ ...prev, isLoading: false, data: tempReportDataHolder, total: totalCount }))

            }
        } catch (error) {
            setPageState((prev) => ({ ...prev, isLoading: true }))
            console.error(error)
        }
    };
    const [pageState, setPageState] = useState({
        isLoading: true,
        data: [],
        total: 3,
        page: 1,
        pageSize: 50
    })

    useEffect(() => {
        if (!isLoading)
            fetchData();
    }, [isLoading, status, pageState.page, pageState.pageSize])


    const resetPageState = () => {
        setPageState({
            isLoading: true,
            data: [],
            total: 3,
            page: 1,
            pageSize: 50
        })
    }

    let muiColumns = [
        {
            flex: 1,
            field: "id", headerName: "id",
            hide: true
        },
        {
            flex: 1,
            field: "sender", headerName: "Sender",
        },
        {
            flex: 1,
            field: "receiver", headerName: "Receiver",
        },
        {
            flex: 1,
            field: "category", headerName: "Category",
        },
        {
            flex: 1,
            field: "date", headerName: "Date"
        },
        {
            flex: 1,
            field: "rejectMessage", headerName: "Reason",
            hide: status === "rejected" ? false : true
        }
    ];

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [isExportingData, setIsExportingloading] = useState(false);


    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const fetchAllDataforExport = async () => {
        try {
            setIsExportingloading(true);
            handleClose(null);
            let res = await axios.get(`${baseUrl}/appreciation/getAppreciationByStatusForExport/${status}`);
            if(res.data && res?.data?.result==="success") {
                let exportData = [];
                res.data.data?.forEach((element) => {
                    let tzoffset = (new Date()).getTimezoneOffset() * 60000;
                    let timestamp = Number(element["date"]);
                    let date = new Date(timestamp - tzoffset)?.toISOString()?.substring(0, 10) ?? "-";
                    let newElement = {...element, date};
                    exportData.push(newElement);
                })
                saveExcel(exportData);
            }
        } catch (e) {
            console.log(e);
        }
    }

    return (
        <Box
            style={{ padding: "1em" }}
        >
            <Box
                sx={{ display: "flex", justifyContent: "flex-end" }}
                style={{
                    marginBottom: "0.5em",
                    width: "100%",
                }}
            >
                <Box
                    sx={{
                        flexGrow: "1",
                        display: "flex",
                        justifyContent: "space-between",
                        gap: "2%",
                        alignItems: "center",
                    }}
                >
                    <Stack direction={"row"} alignItems="end">
                        {/* <Typography variant="h5" sx={{fontSize: "22px"}}>CATEGORY:{" "}</Typography> */}
                        <Select disabled={pageState.isLoading} sx={{ height: "30px" }} defaultValue={"all"} onChange={(e) => {
                            setStatus(e.target.value)
                        }}>
                            <MenuItem value={"all"}>All</MenuItem>
                            <MenuItem value={"approved"}>Approved</MenuItem>
                            <MenuItem value={"pending"}>Pending</MenuItem>
                            <MenuItem value={"rejected"}>Rejected</MenuItem>
                        </Select>
                    </Stack>
                    {/* <FormControl sx={{ width: "20%", height: "5vh" }}>
                        <TextField
                            placeholder="Search..."
                            inputProps={inputSize}
                            InputProps={{
                                endAdornment: <SearchOutlined color="disabled" />,
                            }}
                            onChange={(e) => onChangeSearch(e)}
                        />
                    </FormControl> */}
                    <Button
                        variant="contained"
                        endIcon={<FileDownloadIcon />}
                        aria-describedby={id}
                        onClick={handleClick}
                        sx={{
                            border: "1px solid #F1F5FE",
                            backgroundColor: "#F1F5FE",
                            color: "#0288d1",
                            fontSize: "12px",
                            textTransform: "capitalize",
                            height: "max-content",
                            "&:hover": {
                                backgroundColor: "#F1F5FE",
                                color: "#0288d1",
                                border: "1px solid #0288d1",
                                boxShadow: "1px #000000",
                            },
                        }}
                        disabled={pageState.isLoading}
                    // onClick={() => {
                    //     saveExcel();
                    // }}
                    >
                        Export
                    </Button>
                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                    >
                        <MenuItem onClick={() => saveExcel()}>Export Current Page</MenuItem>
                        <MenuItem onClick={() => fetchAllDataforExport()}>Export All</MenuItem>
                    </Popover>

                </Box>
            </Box>
            <Box>
                <Box sx={{ height: "60vh", width: '100%' }}>
                    <DataGrid
                        rows={pageState?.data ?? 0}
                        rowCount={pageState?.total ?? 0}
                        loading={pageState.isLoading}
                        getRowId={(row) => `${row.id}`}
                        rowsPerPageOptions={[20, 40, 80]}
                        pagination
                        page={pageState.page - 1}
                        pageSize={pageState.pageSize}
                        paginationMode="server"
                        onPageChange={(newPage) => setPageState((prev) => ({ ...prev, page: newPage + 1 }))}
                        onPageSizeChange={(newPageSize) => setPageState((prev) => ({ ...prev, pageSize: newPageSize }))}
                        columns={muiColumns}
                        // checkboxSelection
                        // onSelectionModelChange={(props) => setSelectedAssets(props)}
                        disableSelectionOnClick
                        components={{
                            Pagination: CustomPagination,
                        }}
                    />
                </Box>
            </Box>
            <Backdrop
                sx={{ color: '#ddd', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isExportingData}
            >
                <CircularProgress color="inherit" />
                <Typography>Exporting data...</Typography>
            </Backdrop>
        </Box>
    )
}

function CustomPagination() {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (
        <Pagination
            color="primary"
            count={pageCount}
            page={page + 1}
            onChange={(event, value) => apiRef.current.setPage(value - 1)}
        />
    );
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const StatusDialogDetail = (props) => {
    const handleCloseClick = () => {
        props.setOpenDialog(false);
    };
    return (
        <Dialog sx={{
            "& .MuiDialog-paper": {
                backgroundColor: "#649bd945",
            }
        }} fullScreen open={props?.openDialog} onClose={handleCloseClick} TransitionComponent={Transition} >
            <IconButton sx={{
                position: "absolute",
                top: 0,
                right: 0
            }} edge="start" color="inherit" onClick={handleCloseClick} aria-label="close">
                <Close style={{ color: "#fff" }} />
            </IconButton>
            <DialogContent sx={{
                position: "absolute",
                top: "52px",
                height: "calc(100vh - 52px)",
                width: "100%",
                overflow: "auto",
                borderRadius: '0.5em 0.5em 0 0',
                background: '#fff',
                padding: 0,
                // background: "rgba(29, 29, 17, 0.8)"
            }}>
                <AllAppreciationPage modalStatus={props.status} />
            </DialogContent>
        </Dialog>
    )
}
