import { Close, EmojiEvents, Info } from "@mui/icons-material";
import {
  Stack,
  Typography,
  Box,
  Grid,
  Card,
  CardContent,
  Paper,
  List,
  Skeleton,
  Dialog,
  IconButton,
  DialogContent,
  Slide,
  Tooltip,
} from "@mui/material";
import React, { useState } from "react";
import { RotatingLines } from "react-loader-spinner";
import { useSelector } from "react-redux";
import AppreciationReceived from "../AppreciationReceivedPage/AppreciationReceived";
import './LeaderBoard.css'

// const useStyles = makeStyles((theme) => ({
//   dialogWrapper: {
//     "& .MuiDialog-paper": {
//       backgroundColor: "transparent",
//     },
//   },
//   dialogContent: {
//     position: "absolute",
//     top: "52px",
//     height: "calc(100vh - 52px)",
//     width: "100%",
//     borderRadius: theme.spacing(2.5, 2.5, 0, 0),
//     background: theme.palette.common.white,
//   },
//   closeButton: {
//     position: "absolute",
//     top: theme.spacing(0.75),
//     right: theme.spacing(2),
//   },
// }));

function ValueCards({ template }) {
  return (
    <div>
      <Card id="value-card" sx={{
        height: { xl: "15rem", md: "10rem" },
        border: "solid 1px #ddd",
        //  borderColor: "#007AD4",
        boxShadow: "1px 4px 5px 0px rgba(0,0,0,0.75)"
      }}>
        <Typography
          variant="h5"
          sx={{
            fontSize: "1rem",
            fontWeight: "500",
            margin: "1rem 1rem 0 1rem",
            color: "#007AD4"
          }}
        >
          {template.category}
        </Typography>
        <Grid container sx={{ display: "flex", paddingTop: "0" }}>
          <Grid item md={7}>
            <CardContent sx={{
              flex: "1 0 auto", paddingTop: "0",
              textAlign: "left",
            }} >
              <Typography
                variant="subtitle"
                sx={{
                  fontSize: "0.8rem",
                  color: "#263238",
                }}
              >
                {template.description}
              </Typography>
            </CardContent>
          </Grid>
          <Grid item md={5} xl={4} sx={{ margin: "auto", paddingRight: "0.5rem" }}>
            <img
              width="100%"
              src={`data:image/png;base64,${template?.logo}`}
              alt={template?.category ?? "Category"}
              loading="lazy"
            />
          </Grid>
        </Grid>
      </Card>
    </div>
  );
}

function ValueCardsDashboard({ setSelectedTemplate }) {

  const [userEmail, setUserEmail] = useState("");
  const [userName, setUserName] = useState("");

  const [openUserDetails, setOpenUserDetails] = useState(false)

  const showUserDetails = (_userEmail, _userName) => {
    setUserEmail(_userEmail);
    setUserName(_userName);
    setOpenUserDetails(true);
  }

  const getBadge = (rank) => {
    if (rank > 3)
      return;
    let color = "#fff";
    switch (rank) {
      case 1:
        color = "#FFD700";
        break;
      case 2:
        color = "#C0C0C0"
        break;
      case 3:
        color = "#CD7F32"
        break;
      default:
        color = "#fff";
    }
    return <EmojiEvents fontSize="small" sx={{ color: color }} />
  }

  const templateData = useSelector(state => state.appReducer.templateData);
  const leaderBoardData = useSelector(state => state.appReducer.leaderBoardData);
  return (
    <div>
      <Box className="wbScroll">
        <Stack spacing={3} sx={{ margin: "1rem 0 1rem 1rem" }}>
          <Grid sx={{ height: "270px" }} container gap={2}>
            <Grid sx={{ height: "100%" }} item>
              <Paper elevation={4} sx={{ backgroundSize: "100%", background: "url(./Leaderboard.jpg) no-repeat center", height: "100%", width: "15.5rem", position: "relative", display: "flex", justifyContent: "center", alignItems: "center" }}>
              </Paper>
            </Grid>
            <Grid item flex={1} sx={{ height: "100%", paddingRight: "1rem" }}>
              <Typography sx={{ fontSize: "20px", fontWeight: "bold", color: "#002947" }}>
                Leaderboard
              </Typography>
              <List sx={{ display: "flex", flexDirection: "column", gap: "10px", justifyContent: "stretch", alignContent: "stretch" }}>
                <Paper elevation={0} sx={{ padding: "0.2em", height: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  <Box sx={{ width: "10%" }}>
                    <Typography sx={{ fontWeight: "bold" }}>
                      Rank
                    </Typography>
                  </Box>
                  {/* <Box sx={{ width: "10%" }}>
                    <Typography sx={{ fontWeight: "bold" }}>

                    </Typography>
                  </Box> */}
                  <Box sx={{ width: "30%" }}>
                    <Typography sx={{ fontWeight: "bold" }}>
                      Name
                    </Typography>
                  </Box>
                  <Box sx={{ width: "40%" }}>
                    <Typography sx={{ fontWeight: "bold" }}>
                      Email
                    </Typography>
                  </Box>
                  <Box sx={{ width: "20%", display: "flex", justifyContent: "center" }}>
                    <Typography sx={{ fontWeight: "bold" }}>
                      Cards Received
                    </Typography>
                  </Box>
                </Paper>
                {
                  leaderBoardData?.map((leader, index) => {
                    let count = leader[0];
                    let detail = leader[1];
                    // if (index === 0) return;
                    return (
                      <Paper style={{ position: 'relative' }} onClick={() => showUserDetails(detail?.emailId ?? "", `${detail?.firstName} ${detail?.lastName}`)} className="leader-borad-list-item" key={index} sx={{ backgroundColor: index % 2 === 1 ? "#fff" : "#EAF2F9", padding: "0.2em", height: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <IconButton style={{ position: 'absolute', right: '0' }}>
                          <Tooltip title={"Show details"}>
                            <Info />
                          </Tooltip>
                        </IconButton>
                        <Box sx={{ width: "10%" }}>
                          <Typography sx={{ fontWeight: "bold", color: "#007AD4" }}>
                            #{index + 1}
                          </Typography>
                        </Box>
                        {/* <Box sx={{ width: "10%" }}>
                          {getBadge(index + 1)}
                        </Box> */}
                        <Box sx={{ width: "30%" }}>
                          <Typography>
                            {detail?.firstName} {detail?.lastName}
                          </Typography>
                        </Box>
                        <Box sx={{ width: "40%" }}>
                          <Typography>
                            {detail?.emailId}
                          </Typography>
                        </Box>
                        <Box sx={{ width: "20%", display: "flex", justifyContent: "center" }}>
                          <Typography>
                            {count}
                          </Typography>
                        </Box>
                      </Paper>
                    )
                  })
                }
                {
                  leaderBoardData.length === 0 && [...Array(4).keys()].map((arr, index) => (
                    <Paper key={index} sx={{ padding: "0.2em", height: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                      <Skeleton height={"100%"} width={"100%"} />
                    </Paper>
                  ))
                }
              </List>
            </Grid>
          </Grid>
          <Grid>
            <Typography
              variant="h5"
              sx={{ fontWeight: "600", color: "#002947" }}
            >
              Choose a Value Card to send
            </Typography>
            <Typography variant="body" sx={{ fontSize: "0.9rem" }}>
              Appreciate your peers by sending them a value card
            </Typography>
          </Grid>
          <Grid container spacing={2} sx={{ width: "97.5%" }}>
            {
              templateData.map((template) => {
                return (
                  <Grid key={template.templateId} item md={3} sx={{ cursor: "pointer" }} onClick={() => setSelectedTemplate(template)}>
                    <ValueCards template={template} />
                  </Grid>)
              })
            }
            {
              templateData.length === 0 &&
              <Box sx={{ width: "100%", height: "250px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>

                <RotatingLines
                  strokeColor="#002947"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="70"
                  visible={true}
                />
                <Typography>Loading...</Typography>
              </Box>
            }
          </Grid>
        </Stack>
      </Box>
      <UserDetailsDialog userName={userName} userEmail={userEmail} openUserDetails={openUserDetails} setOpenUserDetails={setOpenUserDetails} />
    </div>
  );
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const UserDetailsDialog = (props) => {
  const handleCloseClick = () => {
    props.setOpenUserDetails(false);
  };
  return (
    <Dialog sx={{
      "& .MuiDialog-paper": {
        backgroundColor: "#649bd945",
      }
    }} fullScreen open={props?.openUserDetails} onClose={handleCloseClick} TransitionComponent={Transition} >
      <IconButton sx={{
        position: "absolute",
        top: 0,
        right: 0
      }} edge="start" color="inherit" onClick={handleCloseClick} aria-label="close">
        <Close style={{color: "#fff"}} />
      </IconButton>
      <DialogContent sx={{
        position: "absolute",
        top: "52px",
        height: "calc(100vh - 52px)",
        width: "100%",
        borderRadius: '1em 1em 0 0',
        background: '#fff',
        padding: 0,
        // background: "rgba(29, 29, 17, 0.8)"
      }}>
        <AppreciationReceived userName={props?.userName ?? ""} userEmail={props?.userEmail} />
      </DialogContent>
    </Dialog>
  )
}

export default ValueCardsDashboard;
