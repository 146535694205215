import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedNavIndex, setShowDashBoard } from "../../redux/reducers/appReducer";
import PreviewPage from "../PreviewPage/PreviewPage"
import ValueCardsDashboard from "../ValueCardDashboardPage/ValueCardsDashboard";
import "./AppreciatePage.css"

const AppreciatePage = () => {
  const [showPreviewPage, setShowPreviewPage] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const dispatch = useDispatch();

  dispatch(setSelectedNavIndex(0));


  const handleSelectTemplate = (template) => {
    setSelectedTemplate(template);
    setShowPreviewPage(true);
    dispatch(setShowDashBoard(false));
  }

  const showDashBoard = () => {
    setShowPreviewPage(false);
  }

  const showDashBoardReduxState = useSelector(state => state.appReducer.showDashBoard);

  return (
    showPreviewPage && !showDashBoardReduxState ? <PreviewPage showDashBoard={showDashBoard} selectedTemplate={selectedTemplate}/> : <ValueCardsDashboard setSelectedTemplate={handleSelectTemplate} />
  );
};

export default AppreciatePage;
