export const msalConfig = {
  auth: {
    clientId: "518d848b-f602-4c2b-9c1e-0f721b8aabd3",
    authority: "https://login.microsoftonline.com/incturet.onmicrosoft.com", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    // redirectUri: "http://localhost:3000",
    redirectUri: "https://ivalue.cherrywork.com/",
  },
  cache: {
    cacheLocation: "localStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ["User.Read"],
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com",
};
